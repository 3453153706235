import React, { useEffect, useReducer } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Pagination from "../components/pagination"

export const query = graphql`
  query publications {
    allContentfulPublications {
      nodes {
        id
        year
        publications {
          json
        }
      }
    }
    mostRecentPublication: allContentfulPublications(
      sort: { fields: year, order: DESC }
      limit: 1
    ) {
      nodes {
        id
        year
        publications {
          json
        }
      }
    }
  }
`

const initialState = {
  years: [],
  activeYear: {},
}

const reducer = (state, action) => {
  if (action.type === "SET_YEARS") {
    return {
      years: action.payload.years,
      activeYear: action.payload.activeYear,
    }
  }
  if (action.type === "UPDATE_ACTIVE_YEAR") {
    return {
      ...state,
      activeYear: action.payload.activeYear,
    }
  }
  return state
}

const Publications = ({ data }) => {
  const { allContentfulPublications, mostRecentPublication } = data
  const [state, dispatch] = useReducer(reducer, initialState)

  const updateActiveYear = year => {
    dispatch({ type: "UPDATE_ACTIVE_YEAR", payload: { activeYear: year } })
  }

  useEffect(() => {
    dispatch({
      type: "SET_YEARS",
      payload: {
        years: allContentfulPublications.nodes,
        activeYear: mostRecentPublication.nodes[0],
      },
    })
  }, [allContentfulPublications.nodes, mostRecentPublication.nodes])

  return (
    <Layout>
      <div className="container container--min-h">
        <div className="section">
          <h1 className="title is-1 mb-6">Publications</h1>
          <Pagination
            years={state.years}
            activeYear={state.activeYear}
            updateActiveYear={updateActiveYear}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Publications
