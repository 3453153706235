import React from "react"
import moment from "moment"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { formatYear } from "../utils"

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

const Pagination = ({ years, activeYear, updateActiveYear }) => {
  return (
    <>
      <nav class="pagination" role="navigation" aria-label="pagination">
        <ul className="pagination-list">
          {years
            .sort((a, b) => (moment(a.year).isBefore(b.year, "year") ? 1 : -1))
            .map(year => (
              <li key={year.id} className="pagination-link p-0 mb-2">
                <button
                  className="button is-ghost"
                  onClick={() => updateActiveYear(year)}
                >
                  {formatYear(year)}
                </button>
              </li>
            ))}
        </ul>
      </nav>
      <div className="content">
        {activeYear.publications &&
          documentToReactComponents(activeYear.publications.json, options)}
      </div>
    </>
  )
}

export default Pagination
